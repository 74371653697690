<template>
  <div class="users-form">
    <header
      v-if="hasHeaderSlot"
      class="users-form__header"
    >
      <slot name="header" />
    </header>

    <div class="users-form__body">
      <slot />
    </div>

    <footer
      v-if="hasFooterSlot"
      class="users-form__footer"
    >
      <slot name="footer" />
    </footer>
  </div>
</template>

<script>
export default {
  name: 'UsersForm',
  computed: {
    hasFooterSlot() {
      return !!this.$slots.footer;
    },

    hasHeaderSlot() {
      return !!this.$slots.header;
    },
  },
};
</script>

<style lang="scss">
.users-form {
  footer {
    padding: 0 0 30px;
    overflow: hidden;
  }
}

.users-form__header {
  text-align: center;
  margin-bottom: 41px;
}

.users-form__header-title {
  font-weight: $fw-semi-bold;
  font-size: $fs-heading-m;
  line-height: 120%;
  color: black;
  margin-bottom: 17px;
}

.users-form__header-subtitle {
  font-size: $fs-text-l;
  line-height: 24px;
  margin-bottom: 20px;
  color: $sk-grey;
  text-align: center;
  font-weight: $fw-medium;
}

.users-form__body {
  margin-bottom: 20px;
}

.users-form__footer {
  display: flex;
  color: $sk-grey;
  font-size: $fs-text-m;
  line-height: 24px;
  align-items: center;
  justify-content: center;

  p {
    margin: 0 8px 0 0;

    @media (max-width: $md-width) {
      margin: 0;
      text-align: center;
    }
  }
}
</style>

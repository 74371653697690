<template>
  <div class="users-layout">
    <div class="users-layout__left">
      <div class="users-layout__left-content">
        <div class="users-layout__left-content-wrapper">
          <div class="users-layout__left-container">
            <a
              :href="publicWebsiteHost"
              class="users-layout__left__logo"
            >
              <SkLogo />
            </a>

            <slot />
          </div>
        </div>
      </div>
    </div>

    <div class="users-layout__right d-none d-lg-block">
      <div class="users-layout__right-content">
        <div class="users-layout__right-content-wrapper">
          <div class="users-layout__right__text">
            {{ $t('slogan.first') }}
            <br>
            {{ $t('slogan.second') }}
            <br>
            {{ $t('slogan.third') }}
          </div>

          <SkLargeS class="users-layout__right__logo" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SkLargeS } from '@branding';
import { SkLogo } from '@skelloapp/skello-ui';

export default {
  name: 'UsersLayout',
  components: { SkLargeS, SkLogo },
  computed: {
    publicWebsiteHost() {
      return document.querySelector('[name=public-website-host]').content;
    },
  },
};
</script>

<style lang="scss">
.users-layout__left {
  height: 100vh;
  position: relative;
  min-height: 500px;
  padding: 0 24px;

  @media (min-width: $lg-width) {
    margin-right: 500px;
  }
}

.users-layout__left-content {
  display: table;
  width: 100%;
  height: 100%;
}

.users-layout__left-content-wrapper {
  position: relative;
  display: table-cell;
  vertical-align: middle;
  padding-top: 100px;
  padding-bottom: 35px;
}

.users-layout__left-container {
  margin: 0 auto;
  max-width: 570px;
}

.users-layout__left__logo {
  position: absolute;
  top: 30px;
  left: 30px;

  @media (max-width: $md-width) {
    left: calc(50% - 52px);
  }
}

.users-layout__right {
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  width: 500px;
}

.users-layout__right-content {
  background: $sk-royal-blue;
  height: 100%;
  color: white;
  font-weight: $fw-semi-bold;
  font-size: $fs-display-s;
  line-height: 110%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.users-layout__right-content-wrapper {
  @media (max-height: 650px) {
    display: none;
  }
}

.users-layout__right__text {
  margin-bottom: 11px;
  margin-left: -85px;
}

.users-layout__right__logo {
  @media (max-height: 650px) {
    max-height: 60%;
  }
}
</style>

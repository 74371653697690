<template>
  <div :class="bannerClasses">
    <p>{{ message }}</p>
    <router-link
      v-if="link"
      :to="link.url"
    >
      {{ link.anchor }}
    </router-link>
  </div>
</template>

<script>
const BANNER_TYPES = ['info', 'success', 'error'];

export default {
  name: 'Banner',
  props: {
    message: {
      type: String,
      required: true,
    },
    hidden: {
      type: Boolean,
      default: true,
    },
    link: {
      type: [Object, null],
      default: null,
    },
    type: {
      type: String,
      default: 'error',
      validator: value => BANNER_TYPES.includes(value),
    },
  },
  computed: {
    bannerClasses() {
      return {
        [`st-${this.type}-banner`]: true,
        hidden: this.hidden,
      };
    },
  },
};
</script>

<style lang="scss">
.st-error-banner,
.st-success-banner,
.st-info-banner {
  border: none;
  border-radius: 4px;
  font-weight: $fw-medium;
  font-size: $fs-text-m;
  line-height: 120%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 25px;
  min-width: 100%;

  p {
    margin: 0;
  }

  a {
    text-decoration: underline;
    color: white;

    &:hover {
      text-decoration: none;
      color: white;
    }
  }
}

.st-error-banner {
  background-color: $sk-error-10;
  color: $sk-error;
}

.st-success-banner {
  color: white;
  background-color: $sk-success;
}

.st-info-banner {
  color: $sk-black;
  background-color: $sk-blue-5;
}
</style>

<template>
  <div class="st-checkbox__container">
    <label class="st-checkbox">
      <input
        v-model="localValue"
        :disabled="disabled"
        type="checkbox"
      >
      <span :disabled="disabled" />
    </label>
  </div>
</template>

<script>
export default {
  name: 'CheckBox',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localValue: this.value,
    };
  },
  computed: {
    checkBoxContainerClasses() {
      return 'st-checkbox__container';
    },
  },
  watch: {
    localValue(newValue) {
      this.$emit('input', newValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.st-checkbox__container {
  display: flex;
  align-items: center;
}

.st-checkbox {
  position: relative;
  margin: 0;
}

.st-checkbox > input {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  position: absolute;
  opacity: 0;
}

/* Box */
.st-checkbox > span:disabled {
  cursor: help;
}

.st-checkbox > span::before:disabled {
  cursor: crosshair;
}

.st-checkbox > span::before {
  content: "";
  display: inline-block;
  box-sizing: border-box;
  margin: 3px 1px;
  border: 2px solid #c7cbcf;
  border-radius: 2px;
  width: 16px;
  height: 16px;
  vertical-align: top;
  transition: border-color .2s, background-color .2s;
}

/* Checkmark */
.st-checkbox > span::after {
  content: "";
  position: absolute;
  top: 3px;
  left: 1px;
  width: 10px;
  height: 5px;
  border: solid 2px transparent;
  border-right: none;
  border-top: none;
  transform: translate(3px, 4px) rotate(-45deg);
}

.st-checkbox > input:checked + span::before,
.st-checkbox > input:indeterminate + span::before {
  border-color: $sk-blue;
  background-color: $sk-blue;
}

.st-checkbox > input:checked + span::after,
.st-checkbox > input:indeterminate + span::after {
  border-color: white;
}

/* Disabled */
.st-checkbox > input:disabled + span {
  cursor: not-allowed;
  opacity: .6;
}
</style>

// Polyfill for ECMAScript features
import 'core-js/stable';
// This package implements a fully-functional source transformation that takes the syntax for generators/yield
// from ECMAScript 2015 and Asynchronous Iteration proposal and spits out efficient JS-of-today (ES5) that behaves the same way
import 'regenerator-runtime/runtime';
import Vue from 'vue';
import PortalVue from 'portal-vue';
import VueI18n from 'vue-i18n';

import V3Static from '@static-app/app.vue';
import router from '@static-app/app-routes';

import '@static-app/shared/assets/_commons.scss';
import '@skelloapp/skello-ui/dist/skello-ui.css';

import { merge } from '@skello-utils/object';
import staticMessages from '@static-lang';
import CommonsTranslation from '@skello-lang';

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: 'fr',
  fallbackLocale: 'fr',
  silentTranslationWarn: true,
  messages: merge(
    CommonsTranslation,
    staticMessages,
  ),
});

Vue.use(PortalVue);

window.addEventListener('DOMContentLoaded', event => {
  new Vue({
    router,
    i18n,
    render: h => h(V3Static),
  }).$mount('#v3-app-static');
});

<template>
  <div class="st-btn-spinner st-btn-spinner__ring st-btn-spinner__spin" />
</template>

<script>
export default {
  name: 'BtnSpinner',
};
</script>

<style lang="scss">
.st-btn-spinner__wrapper {
  position: relative;
  transition: all .3s;
  transition-timing-function: ease-in;
}

.st-btn-spinner__wrapper::before {
  content: " ";
  display: block;
  opacity: 0;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all .3s;
  transition-timing-function: ease-in;
  background: rgba(240, 240, 240, .8);
}

.st-btn-spinner__wrapper.st-btn-spinner__wrapper--spinning {
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.st-btn-spinner__wrapper.st-btn-spinner__wrapper--spinning > .st-btn-spinner {
  opacity: 1;
  visibility: visible;
  z-index: 99999;
}

.st-btn-spinner__wrapper.st-btn-spinner__wrapper--spinning::before {
  opacity: .6;
  display: block;
  z-index: 99999;
}

.st-btn-spinner__ring {
  width: 1em;
  height: 1em;
  position: relative;
  color: inherit;
  display: inline-block;
}

.st-btn-spinner__ring::after {
  position: absolute;
  margin: auto;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: " ";
  display: inline-block;
  background: center center no-repeat;
  background-size: cover;
}

.st-btn-spinner__ring::after {
  border-radius: 50%;
  border-style: solid;
  border-width: .15em;
  -webkit-background-clip: padding-box;
  border-color: currentColor currentColor currentColor transparent;
  box-sizing: border-box;
}

@keyframes st-btn-spinner__spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
  }

  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes st-btn-spinner__spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
  }

  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.st-btn-spinner.st-btn-spinner__spin {
  -webkit-animation: st-btn-spinner__spin 1s infinite;
  animation: st-btn-spinner__spin 1s infinite;
}
</style>

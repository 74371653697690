import fr from './static.fr.yml';
import en from './static.en.yml';
import es from './static.es.yml';
import de from './static.de.yml';
import it from './static.it.yml';

export default {
  ...fr,
  ...en,
  ...es,
  ...de,
  ...it,
};

import Vue from 'vue';
import VueRouter from 'vue-router';

// Components
import Login from './users/login';
import ForgottenPassword from './users/forgotten_password';
import AcceptInvitation from './users/accept_invitation/AcceptInvitation';
import ResetPassword from './users/reset_password';
import ErrorPage from '../app/shared/components/ErrorPage';

Vue.use(VueRouter);

const routes = [
  {
    path: '/users/sign_in',
    component: Login,
    name: 'Login',
  },
  {
    path: '/users/password/new',
    component: ForgottenPassword,
    name: 'ForgottenPassword',
  },
  {
    path: '/users/password/edit',
    component: ResetPassword,
    name: 'ResetPassword',
  },
  {
    path: '/users/invitation/accept',
    component: AcceptInvitation,
    name: 'AcceptInvitation',
  },
  {
    path: '*',
    component: ErrorPage,
    name: 'error-page',
  },
];

const Router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default Router;

/* eslint-disable  max-len */
/* eslint-disable  no-useless-escape */

export const isPresent = (value, min = 2) => (
  value && value.trim().length >= min
);

export const isEmail = value => value && /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value);

/**
 * Numbers matching :
 * 0689912549
 * 06 89 91 25 49
 * +33698912549
 * +33 6 79 91 25 49
 * +33 - 6 - 79 - 91 - 25 - 49
 * (555) - 555 - 5555
 * 555 - 555 - 5555
 * +1 - 238 6 79 91 25 49
 * +1 - 555 - 532 - 3455
 * +15555323455
 * +7 06 79 91 25 49
*/
export const isPhoneNumber = value => value && /((?:\+|00)[17](?: |\-)?|(?:\+|00)[1-9]\d{0,2}(?: |\-)?|(?:\+|00)1\-\d{3}(?: |\-)?)?(0\d|\([0-9]{3}\)|[1-9]{0,3})(?:((?: |\-)[0-9]{2}){4}|((?:[0-9]{2}){4})|((?: |\-)[0-9]{3}(?: |\-)[0-9]{4})|([0-9]{7}))$/.test(value);

export const sameValues = (value1, value2) => (
  value1 && value2 && (value1 === value2)
);

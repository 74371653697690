<template>
  <button
    :class="submitButtonClasses"
    :disabled="spinner"
    @click="handleClick"
  >
    <BtnSpinner v-if="spinner" />
    <template v-else>
      {{ text }}
    </template>
  </button>
</template>

<script>
// Components
import BtnSpinner from '../BtnSpinner';

export default {
  name: 'SubmitBtn',
  components: {
    BtnSpinner,
  },
  props: {
    text: {
      type: String,
      required: true,
    },
    spinner: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    submitButtonClasses() {
      return {
        'st-submit-btn': true,
        'st-btn-spinner__wrapper': true,
        'st-btn-spinner__wrapper--spinning': this.spinner,
      };
    },
  },
  methods: {
    handleClick() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss">
.st-submit-btn {
  width: 100%;
  height: 50px;
  background-color: $sk-blue-5;
  border: none;
  border-radius: 4px;
  color: $sk-blue;
  font-weight: $fw-semi-bold;
  font-size: $fs-text-m;
  line-height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    transition: background-color .3s ease;
    background-color: $sk-blue-10;
  }

  &:disabled {
    color: $sk-grey-30;
    background: $sk-grey-10;

    &:hover {
      cursor: not-allowed;
    }
  }
}
</style>
